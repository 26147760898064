<template>
  <div class="create-category-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Activity' }"
        >活動管理
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'ActivityCategory' }"
        >類別管理
      </el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{ name: 'DetailActivityCategory', params: { id: categoryId } }"
        >類別內容</el-breadcrumb-item
      >
      <el-breadcrumb-item>新增次類別</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <el-form
        ref="categoryForm"
        :model="subcategory_data"
        :rules="subcategory_rules"
      >
        <el-form-item label="中文名稱" prop="name_zh">
          <el-input
            v-model="subcategory_data.name_zh"
            maxlength="64"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="英文名稱" prop="name_en">
          <el-input
            v-model="subcategory_data.name_en"
            maxlength="64"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
            <el-button type="success" @click="handleSubmit">送出</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { createActivitySubcategory } from "@/api/activity-subcategory";

export default {
  name: "CreateCategory",
  data() {
    return {
      subcategory_data: {
        category_id: 0,
        name_zh: "",
        name_en: "",
      },
      subcategory_rules: {
        name_zh: [
          { required: true, message: "請輸入中文名稱", trigger: "blur" },
          { max: 64, message: "長度過長", trigger: "blur" },
        ],
        name_en: [
          { required: true, message: "請輸入英文名稱", trigger: "blur" },
          { max: 64, message: "長度過長", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    categoryId() {
      return +this.$route.params.id;
    },
  },
  created() {
    this.subcategory_data.category_id = this.categoryId;
  },
  methods: {
    handleSubmit() {
      this.$refs.categoryForm.validate(async (valid) => {
        if (valid) {
          await createActivitySubcategory(this.subcategory_data);

          this.$message({
            type: "success",
            message: "創建成功",
          });

          await this.handleRedirect();
        }
      });
    },
    async handleRedirect() {
      await this.$router.push({ name: "DetailActivityCategory" });
    },
    handleChangePage(page) {
      this.setup.page = page;
      this.handleGetCategories();
    },
  },
};
</script>

<style lang="scss" scoped></style>
