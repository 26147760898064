import { render, staticRenderFns } from "./create-subcategory.vue?vue&type=template&id=1f0c59e4&scoped=true&"
import script from "./create-subcategory.vue?vue&type=script&lang=js&"
export * from "./create-subcategory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f0c59e4",
  null
  
)

export default component.exports